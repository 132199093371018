import { template as template_1c7abb55a1cb42f69146b89709a8b30f } from "@ember/template-compiler";
import DButton from "discourse/components/d-button";
const BackButton = template_1c7abb55a1cb42f69146b89709a8b30f(`
  <DButton
    @action={{@onGoBack}}
    @label="topic.timeline.back"
    @title="topic.timeline.back_description"
    class="btn-primary btn-small back-button"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default BackButton;
