import { template as template_04fb5137d97942b0b71ff80b34013840 } from "@ember/template-compiler";
const FKLabel = template_04fb5137d97942b0b71ff80b34013840(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
